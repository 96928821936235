.carousel-item-margin {
  margin-right: 10px; /* Gap to the right of each item */
  padding-left: 10px;
}
.custom-dot-list-style button {
  border: none;
  background: #555555;
}

.custom-carousel-container {
  max-width: 1200px; /* or any other width */
  margin: 0px; /* This will center the carousel if the width is less than the screen width */
}
