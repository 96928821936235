.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Ensure the logo and menu items are aligned as per the grid layout */
.ant-row {
  width: 100%;
}

/* Adjust the visibility of the hamburger menu icon */
.hamburger {
  display: none;
}

/* Media query for mobile devices */
@media screen and (max-width: 768px) {
  .hamburger {
    display: inline-block;
  }

  .ant-menu-horizontal {
    display: none;
  }

  /* Show the vertical menu when the hamburger icon is clicked */
  .ant-menu-vertical {
    display: block;
  }
}
